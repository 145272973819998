export const createVirtualPATH = (env) => {
    const host = new URL(env.APP_URL);

    const baseUrl =
        host.protocol + '//' + host.hostname + (host.port ? ':' + host.port : '');

    return env.APP_URL.replace(baseUrl, '');
};

export const withBaseName = (url) => {
    return url === '' || url === undefined ? '/' : url;
};