import Vue from "vue";
import Vuetify from "vuetify/lib/framework";
import es from "vuetify/lib/locale/es";
import "vuetify/dist/vuetify.min.css";
Vue.use(Vuetify);

export default new Vuetify({
  theme: {
    dark: false,
    options: {
      customProperties: true,
    },
    themes: {
      light: {
        primary: "#62c3db",
        secondary: "#55BE8C",
        accent: "#c6d600",
        error: "#FF5252",
        info: "#62C3DB",
        success: "#55BE8C",
        warning: "#f69225",
      },
      dark: {
        primary: "#62C3DB",
        secondary: "#5170b5",
        accent: "#c6d600",
        error: "#FF5252",
        info: "#62C3DB",
        success: "#55BE8C",
        warning: "#f69225",
      },
    },
  },
  lang: {
    locales: { es },
    current: "es",
  },
  icons: {
    iconfont: "mdi",
  },
});
