import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import firebase from "firebase/compat/app";
import VueFirestore from "vue-firestore";
import vuetify from './plugins/vuetify'
import "ant-design-vue/lib/transfer/style/css";
import "ant-design-vue/dist/antd.css";
import './main.scss';
import VueMask from 'v-mask'
Vue.use(VueMask);

Vue.config.productionTip = false
Vue.use(firebase);
Vue.use(VueFirestore);

Object.defineProperty(Vue.prototype, "firebase", {
  get() {
    return this.$root.firebase;
  },
});
new Vue({
  router,
  store,
  vuetify,
  render: h => h(App)
}).$mount('#app')
