<template>
  <div class="logins">
    <div class="wrap-login-container">
      <img
        width="250px"
        class="mb-5"
        style="z-index: 100"
        src="@/assets/logo-01.svg"
        alt=""
        srcset=""
      />

      <v-card class="wrap-login mx-2 py-2" elevation="0">
        <v-card-title>
          <span class="display-1 font-weight-bold" style="width: 100%">
            <span class="font-weight-light">Sistema</span>
            Administrativo
          </span>
        </v-card-title>
        <v-card-subtitle
          >Ingresa tu dirección de correo y contraseña para
          ingresar</v-card-subtitle
        >
        <v-card-text>
          <v-form
            style="width: 100%"
            ref="form"
            v-model="valid"
            lazy-validation
            @keyup.enter.native="onNext"
          >
            <v-text-field
              label="Correo Electrónico"
              v-model="email"
              :rules="[rules.required, rules.email]"
              ref="mailAddress"
              type="email"
              hint="correo@servidor.tldn"
              autocomplete="email"
              filled
              style="border-radius: 10px"
              :disabled="loading"
              rounded
              dense
            ></v-text-field>

            <v-text-field
              v-model="password"
              label="Contraseña"
              hint="la contraseña debe tener más de 8 carácteres"
              autocomplete="current-password"
              :append-icon="show1 ? 'mdi-eye' : 'mdi-eye-off'"
              :rules="[rules.required, rules.min]"
              :type="show1 ? 'text' : 'password'"
              filled
              :disabled="loading"
              rounded
              dense
              style="border-radius: 10px"
              @click:append="show1 = !show1"
            ></v-text-field>
            <div
              style="
                display: flex;
                justify-content: center;
                margin-bottom: 20px;
              "
              id="recaptcha-container"
            ></div>
          </v-form>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions class="flex-colum">
          <v-btn
            color="primary"
            style="border-radius: 10px"
            block
            :loading="loading"
            large
            :elevation="0"
            :minHeight="50"
            @click="onNext"
            ><b>Ingresar</b></v-btn
          >
          <v-btn
            @click="passReset"
            color="gray"
            dense
            block
            large
            text
            :elevation="0"
            :loading="loading"
            :disabled="loading"
            >Olvidaste tu contraseña?</v-btn
          >
        </v-card-actions>
      </v-card>
      <img
        width="200px"
        class="mt-5"
        style="z-index: 100"
        src="@/assets/logoGobW.svg"
        alt=""
        srcset=""
      />
    </div>
  </div>
</template>

<script>
import { mapActions } from "vuex";
import { db, fb } from "@/firebase";
import moment from "moment-timezone";
import "moment/locale/es";
import axios from "axios";
moment.locale("es");
export default {
  name: "login",
  components: {},
  data() {
    return {
      logo: require("@/assets/logo-01.svg"),
      show1: false,
      otpDialog: false,
      authObject: null,
      verificationId: null,
      loading: false,
      email: "",
      password: "",
      valid: true,
      rules: {
        required: (value) => !!value || "Obligatorio",
        min: (v) => (v && v.length >= 8) || "Mínimo 8 caracteres",
        // emailMatch: () => "The email and password you entered don't match",
        email: (v) => {
          const pattern =
            /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
          return pattern.test(v) || "Correo Invalído";
        },
      },
    };
  },
  methods: {
    ...mapActions(["Alert_"]),

    showMessage(msg) {
      this.Alert_({
        text: msg,
        timeout: 2000,
        btn_closed: true,
        icon: false,
        iconType: "mdi-check",
        type: "error",
      });
      this.loading = false;
      // grecaptcha.reset(window.recaptchaWidgetId);
    },
    Message(type, msg) {
      this.Alert_({
        text: msg,
        timeout: 2000,
        btn_closed: true,
        icon: false,
        iconType: "mdi-check",
        type: type,
      });
      this.loading = false;
      // grecaptcha.reset(window.recaptchaWidgetId);
    },
    async logAccess(response, result) {
      let ip = await axios({
        url: "https://get.geojs.io/v1/ip/geo.json",
      });

      let data = {
        result,
        ip: ip.data ? ip.data : {},
        date: moment().tz("America/Tegucigalpa").toDate(),
        domain: document.domain,
      };

      if (result == "success") {
        data.uid = response.user.uid;
        data.email = response.user.email;
      } else {
        data.email = this.email;
        data.error = {
          code: response.code || "no disponible",
          message: response.message,
        };
      }

      await db.collection("accessLog").add(data);
    },
    passReset() {
      let emailAddress = this.email;
      fb.auth().languageCode = "es";

      if (this.$refs.mailAddress.validate()) {
        fb.auth()
          .sendPasswordResetEmail(emailAddress)
          .then(() => {
            // Email sent.
            console.log("email sent");

            this.Message(
              "info",
              "Correo enviado, revisa tu bandeja de entrada"
            );
          })
          .catch((error) => {
            console.log(error);

            this.Message(
              "error",
              "Error al enviar correo, verifica tu conexión a internet"
            );
            // An error happened.
          });
      } else {
        this.Message(
          "error",
          "Ingresa tu dirección de correo electrónico para enviarte un enlace de reinicio de contraseña"
        );
      }
    },
    onNext() {
      this.loading = true;
      if (this.$refs.form.validate()) {
        // fb.auth()
        //   .setPersistence(fb.auth.Auth.Persistence.SESSION)
        //   .then(() => {

        fb.auth()
          .signInWithEmailAndPassword(this.email, this.password)
          .then((response) => {
            this.loading = false;
            this.logAccess(response, "success");
          })
          .catch((error) => {
            this.loading = false;
            // Handle Errors here.
            console.log(error);
            this.logAccess(error, "failed");
            switch (error.code) {
              case "auth/multi-factor-auth-required":
                this.authObject = error.resolver;
                this.mfaLogin();
                break;

              case "auth/invalid-email":
                this.showMessage(
                  "Por favor ingresa la dirección de correo correctamente"
                );
                break;

              case "auth/user-not-found":
                this.showMessage(
                  "No existen usuarios con este correo. Verifica tu dirección o ponte en contacto con el administrador."
                );

                break;

              case "auth/wrong-password":
                this.showMessage("Contraseña incorrecta");

                break;

              case "auth/argument-error":
                this.showMessage(
                  "El correo y la contraseña deben ser campos validos"
                );
                break;

              default:
                this.showMessage(error.message);

                break;
            }
            // ...
          });
        // });
      } else {
        this.showMessage("Llena todos los campos para continuar");
      }
    },
  },
  mounted() {},
};
</script>
<style lang="scss">
@import "@/_responsive.scss";
@import "@/main.scss";

.logins {
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-blend-mode: exclusion;

  background-image: radial-gradient(circle at 30% -10%, #d7f1f6, #43b0cd);
  flex-direction: column;
  // background-image: url('@/assets/fondo.avif');
  background-blend-mode: multiply;
  background-size: cover;
  background-repeat: no-repeat;
  object-fit: cover;

  .wrap-login-container {
    height: auto;
    width: auto;
    // background-color: #fff;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    // #dbe400
    .wrap-login {
      max-width: 480px;

      border-radius: 1.5em !important;
      display: flex;
      justify-content: center;
      flex-direction: column;
      align-items: center;
      box-sizing: border-box;
      padding: 0px 20px;
      box-shadow: 0px 10px 30px #047e9438 !important;
    }
  }
}

.btn-primary---- {
  border-radius: 15px;
  // background-image: radial-gradient(circle at 0% 0, #62c3db, #48c98d)
  // box-shadow: 0px 4px 10px rgba(255, 89, 0, 0.2);
}

.primary {
  border-radius: 15px;
  // background-image: radial-gradient(circle at 0% 0, #62c3db, #48c98d) !important;
  // box-shadow: 0px 4px 10px rgba(255, 89, 0, 0.2);
}

// background-image: radial-gradient(circle at 0% 0, #62c3db54, #48c98d53);

/* ----------------------------------------------
   * Generated by Animista on 2022-8-16 15:43:45
   * Licensed under FreeBSD License.
   * See http://animista.net/license for more info. 
   * w: http://animista.net, t: @cssanimista
   * ---------------------------------------------- */

/**
   * ----------------------------------------
   * animation color-change-2x
   * ----------------------------------------
   */

.flex-colum {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: baseline;
  width: 100%;
  height: auto;
}
</style>
