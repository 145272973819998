<template>
  <v-app>
    <v-app-bar v-if="appFrame" app key="app_bar" clipped-left color="#fff">
      <img src="@/assets/logo-01.svg" width="200px" />
      <span class="dev-tag" v-if="isProd == false"
        >DEV <v-icon dark> mdi-xml </v-icon>
      </span>

      <v-spacer></v-spacer>
      <transition name="slide-fade">
        <v-text-field
          v-if="$store.state.visibleSearch"
          v-model="search"
          solo-inverted
          clearable
          rounded
          flat
          hide-details
          :label="`Buscar ${$store.state.searchTerm}`"
          prepend-inner-icon="mdi-magnify"
          style="background-color: #fff; border-radius: 10px"
        />
      </transition>
      <v-spacer></v-spacer>
      <span
        v-if="!isMobile"
        class="headline font-weight-bold titleBasa ml-0 mr-5 black--text d-none d-md-block"
        style="position: relative; top: -6px"
      >
        Plataforma
        <span
          style="
            position: absolute;
            bottom: -18px;
            left: 0;
            font-size: 14px;
            font-weight: normal;
            color: black;
            width: 100%;
            text-align: right;
          "
        >
          Administrativa
        </span>
      </span>
    </v-app-bar>
    <Snackbar style="z-index: 1000; width: 100%; position: fixed" />
    <v-main>
      <router-view />
    </v-main>
    <v-navigation-drawer
      permanent
      app
      nav
      key="app_nav"
      :clipped="isMobile ? false : true"
      v-model="drawer"
      style="z-index: 90"
      :mini-variant.sync="mini"
      :app="isMobile ? false : true"
      :absolute="isMobile"
      :class="{ 'nav-drawer-mobile': isMobile }"
      color="#122c3a"
      width="280px"
      v-if="navBar && hideMobileNavigation"
      dark
    >
      <div class="user-descrips" v-if="user">
        <div>
          <v-list rounded dense style="background: transparent !important">
            <v-list-item two-line class="pa-0 ma-0">
              <v-list-item-avatar color="info">
                <v-img src="@/assets/user.svg"></v-img>
              </v-list-item-avatar>

              <v-list-item-content class="mt-3">
                <v-list-item-title
                  class="subtitle-1 white--text font-weight-bold"
                >
                  {{ user.name ? user.name : "Usuario" }}
                  <v-icon
                    @click="$router.push({ path: '/profile' })"
                    class="mr-3 mb-3"
                    style="cursor: pointer; float: right"
                    color="#ffffff"
                  >
                    fas fa-edit
                  </v-icon>
                </v-list-item-title>

                <v-list-item-subtitle class="primary--text">
                  {{ user.type | typeUser }} /
                  {{ user.role | typeRole }}
                </v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
          </v-list>
        </div>
      </div>
      <v-divider></v-divider>
      <v-text-field
        v-model="searchMenu"
        hide-details
        class="pa-3 mb-3"
        rounded
        filled
        dense
        prepend-inner-icon="mdi-magnify"
        clearable
        placeholder="Buscar"
      />
      <v-divider></v-divider>
      <v-list rounded dense nav v-if="!searchMenu">
        <v-list-group
          prepend-icon="mdi-account-circle"
          :prepend-icon="i.icon"
          v-for="(i, index) of filteredMenu"
        >
          <template v-slot:activator>
            <v-list-item-title>{{ i.name }}</v-list-item-title>
          </template>

          <v-list-item
            v-for="item in i.links"
            :key="item.name"
            link
            :to="item.link"
            class="ml-6"
            color="primary"
          >
            <v-list-item-title>{{ item.name }}</v-list-item-title>
            <v-list-item-icon>
              <v-icon v-text="item.icon"></v-icon>
            </v-list-item-icon>
          </v-list-item>
        </v-list-group>
      </v-list>
      <v-list rounded dense nav v-if="searchMenu">
        <v-list-item
          v-for="item in filteredMenu"
          :key="item.name"
          link
          :to="item.link"
          class="ml-6"
          color="primary"
        >
          <!-- <v-list-item-icon>
            <v-icon >{{ item.icon }}</v-icon>
          </v-list-item-icon> -->

          <v-list-item-content>
            <v-list-item-title>{{ item.name }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>

      <template v-slot:append>
        <div class="pa-2">
          <v-btn
            block
            color="primary"
            style="border-radius: 10px"
            @click="logOut"
          >
            <v-icon small class="mr-2">fa-sign-out-alt</v-icon>

            Cerrar Sesión</v-btn
          >
        </div>
      </template>
    </v-navigation-drawer>
    <v-footer app padless color="#121e3b" dark v-if="appFrame && !isMobile">
      <v-col class="text-left caption pa-0" cols="2">
        <span class="ml-12">
          <v-icon>mdi-chart-sankey-variant</v-icon>
          v{{ version }}
        </span>
      </v-col>
      <v-col class="text-center caption pa-0" cols="8" v-if="!isMobile">
        <span> ENEE &copy; 2023</span>
        <v-btn
          @click="refreshRequired = true"
          x-small
          dark
          absolute
          right
          text
          outlined
        >
          Refrescar y borrar cache
        </v-btn>
      </v-col>
    </v-footer>
    <v-snackbar top v-model="refreshRequired">
      La versión del panel administrativo se ha actualizado. Refresca la página
      para disfrutar de nuevas características
      <v-btn color="primary" rounded text @click="refreshPage">
        Refrescar
      </v-btn>
    </v-snackbar>
  </v-app>
</template>

<script>
import { db, fb } from "@/firebase";
import { mapActions, mapState } from "vuex";
import Snackbar from "@/components/snackbar";
export default {
  name: "App",
  components: {
    Snackbar,
  },
  data: () => ({
    version: process.env.VUE_APP_VERSION,
    appFrame: false,

    navBar: false,
    user: null,
    isMobile: false,
    searchMenu: null,
    drawer: null,
    mini: false,
    scheduledOrders: [],
    hideMobileNavigation: true,
    versionListener: null,
    loading: true,
    snackbar: false,
    snackbarText: null,
    refreshRequired: false,
    items: [{ title: "Navbar", icon: "mdi-menu", link: "/settings/navbar" }],
    home: [
      {
        title: "Covers inicio",
        icon: "mdi-image-area",
        link: "/inicio/banners",
      },
    ],
    baseRoute: {
      sudo: "/settings/navbar",
      admin: "/news/news",
      support: "/documents/files",
      biddingAdmin: "/licitaciones/config",
      viewerBidding: "/licitaciones/config",
      // biddingAdmin" || this.role == "viewerBidding"
    },
    admins: [
      ["Management", "mdi-account-multiple-outline"],
      ["Settings", "mdi-cog-outline"],
    ],
    cruds: [
      ["Create", "mdi-plus-outline"],
      ["Read", "mdi-file-outline"],
      ["Update", "mdi-update"],
      ["Delete", "mdi-delete"],
    ],
  }),
  filters: {
    filterTypeName(type) {
      return type == "sudo" ? "Super Usuario" : "Administrador";
    },
    RoleFilter(type) {
      const role = {
        admin: "Comunicaciones",
        sudo: "Área IT",
        support: "Soporte",
        viewerBidding: "Visualizador de Licitaciones",
      };
      return role[type];
    },
    typeRole(e) {
      const role = {
        admin: "Comunicaciones",
        sudo: "Área IT",
        biddingAdmin: "Licitaciones",
        viewerBidding: "Visualizador de Licitaciones",
        support: "Soporte",
      };
      return role[e];
    },
    typeUser(e) {
      const type = {
        admin: "Administrador",
        sudo: "Super usuario",
      };
      return type[e];
    },
  },
  computed: {
    ...mapState(["role", "selectedBusiness", "search"]),
    isProd() {
      return process.env.NODE_ENV == "production" ? true : false;
    },
    filteredMenu() {
      if (!this.searchMenu) {
        return this.roleMenu;
      }

      let menuCopy = [];
      let tempMenu = Object.assign([], this.roleMenu);

      tempMenu.forEach((menu) => {
        //SEGUNDO NIVEL

        if (menu.links && menu.links.length) {
          menu.links.forEach((child) => {
            //TERCER NIVEL
            if (child.links && child.links.length) {
              menuCopy = [...menuCopy, ...child.links];
            } else {
              menuCopy.push(child);
            }
          });
        } else {
          menuCopy.push(menu);
        }
      });

      return menuCopy.filter((e) =>
        e.name
          .toUpperCase()
          .normalize("NFD")
          .replace(/[\u0300-\u036f]/g, "")
          .includes(
            this.searchMenu
              .toUpperCase()
              .normalize("NFD")
              .replace(/[\u0300-\u036f]/g, "")
          )
      );
    },
    roleMenu() {
      if (this.role == "sudo") {
        return [
          {
            name: "Inicio",
            icon: "mdi-home",
            links: [
              {
                name: "Covers inicio",
                icon: "mdi-image-area",
                link: "/inicio/banners",
              },
              {
                name: "Galería Inicio",
                icon: "mdi-image-area",
                link: "/inicio/galery",
              },
            ],
          },

          {
            name: "Noticias",
            icon: "mdi-newspaper-variant",
            links: [
              {
                name: "Noticias",
                icon: "mdi-newspaper-variant",
                link: "/news/news",
              },
              {
                name: "Categorías",
                icon: "mdi-shape-outline",
                link: "/news/categories",
              },
              {
                name: "Páginas",
                icon: "mdi-page-next-outline",
                link: "/news/pages",
              },
            ],
          },
          {
            name: "Documentos",
            icon: "mdi-file-document-multiple",
            links: [
              {
                name: "Todos los archivos",
                icon: "mdi-file-document-multiple",
                link: "/documents/files",
              },

              // {
              //   name: "Comunicados",
              //   icon: "mdi-file-document-outline",
              //   link: "/files/comunicados",
              // },
              // { name: "Boletín", icon: "mdi-menu", link: "/files/Boletín" },
            ],
          },
          {
            name: "Páginas",
            icon: "mdi-file-document",
            links: [
              {
                name: "Páginas documentos",
                icon: "mdi-file-document-multiple",
                link: "/pages/pages",
              },
              {
                name: "Páginas configuración",
                icon: "mdi-cogs",
                link: "/pages/config",
              },
            ],
          },
          {
            name: "Autoproductores",
            icon: "mdi-account-hard-hat",
            links: [
              {
                name: "Actualización mensual",
                icon: "mdi-files",
                link: "/autoproductores/actualizacion-mensual",
              },
              {
                name: "Informes mensuales",
                icon: "mdi-files",
                link: "/autoproductores/history",
              },
            ],
          },
          {
            name: "Licitaciones",
            icon: "mdi-application-settings",
            links: [
              {
                name: "Páginas Licitaciones",
                icon: "mdi-application-brackets",
                link: "/licitaciones/config",
              },
              {
                name: "Usuarios Oferentes",
                icon: "mdi-application-brackets",
                link: "/licitaciones/offerer",
              },
              {
                name: "Documentos",
                icon: "mdi-file",
                link: "/licitaciones/documents",
              },
              // {
              //     name: "Usuarios Pendinetes",
              //     icon: "mdi-application-brackets",
              //     link: "/licitaciones/offererPending",
              //   },
            ],
          },
          {
            name: "Configuración",
            icon: "mdi-cogs",
            links: [
              { name: "Navbar", icon: "mdi-menu", link: "/settings/navbar" },
              {
                name: "Usuarios Administrativos",
                icon: "mdi-user",
                link: "/users/Admin",
              },
              {
                name: "Bitácora de accesos",
                icon: "mdi-files",
                link: "/settings/logs",
              },
            ],
          },
        ];
      }

      if (this.role == "admin") {
        return [
          {
            name: "Inicio",
            icon: "mdi-home",
            links: [
              {
                name: "Covers inicio",
                icon: "mdi-image-area",
                link: "/inicio/banners",
              },
              {
                name: "Galería Inicio",
                icon: "mdi-image-area",
                link: "/inicio/galery",
              },
            ],
          },

          {
            name: "Noticias",
            icon: "mdi-newspaper-variant",
            links: [
              {
                name: "Noticias",
                icon: "mdi-newspaper-variant",
                link: "/news/news",
              },
              {
                name: "Categorías",
                icon: "mdi-shape-outline",
                link: "/news/categories",
              },
              {
                name: "Páginas",
                icon: "mdi-page-next-outline",
                link: "/news/pages",
              },
            ],
          },
          {
            name: "Documentos",
            icon: "mdi-file-document-multiple",
            links: [
              {
                name: "Todos los archivos",
                icon: "mdi-file-document-multiple",
                link: "/documents/files",
              },
              // {
              //   name: "Licitaciones",
              //   icon: "mdi-file",
              //   link: "/documents/licitaciones",
              // },
              // {
              //   name: "Comunicados",
              //   icon: "mdi-file-document-outline",
              //   link: "/files/comunicados",
              // },
              // { name: "Boletín", icon: "mdi-menu", link: "/files/Boletín" },
            ],
          },
          {
            name: "Autoproductores",
            icon: "mdi-account-hard-hat",
            links: [
              {
                name: "Actualización mensual",
                icon: "mdi-files",
                link: "/autoproductores/actualizacion-mensual",
              },
              {
                name: "Informes mensuales",
                icon: "mdi-files",
                link: "/autoproductores/history",
              },
            ],
          },
          {
            name: "Configuración",
            icon: "mdi-cogs",
            links: [
              { name: "Navbar", icon: "mdi-menu", link: "/settings/navbar" },
              // { name: "Usuarios Adminsitrativos", icon: "mdi-user", link: "/users/Admin" },
            ],
          },
        ];
      }
      if (this.role == "support") {
        return [
          {
            name: "Documentos",
            icon: "mdi-file-document-multiple",
            links: [
              {
                name: "Todos los archivos",
                icon: "mdi-file-document-multiple",
                link: "/documents/files",
              },
              {
                name: "Páginas documentos ",
                icon: "mdi-file-document-multiple",
                link: "/pages/pages",
              },

              // {
              //   name: "Licitaciones",
              //   icon: "mdi-file",
              //   link: "/documents/licitaciones",
              // },
              // {
              //   name: "Comunicados",
              //   icon: "mdi-file-document-outline",
              //   link: "/files/comunicados",
              // },
              // { name: "Boletín", icon: "mdi-menu", link: "/files/Boletín" },
            ],
          },
          {
            name: "Autoproductores",
            icon: "mdi-account-hard-hat",
            links: [
              {
                name: "Informes mensuales",
                icon: "mdi-files",
                link: "/autoproductores/history",
              },
            ],
          },
        ];
      }
      if (this.role == "biddingAdmin") {
        return [
          {
            name: "Licitaciones",
            icon: "mdi-application-settings",
            links: [
              {
                name: "Páginas Licitaciones",
                icon: "mdi-application-brackets",
                link: "/licitaciones/config",
              },
              {
                name: "Usuarios Oferentes",
                icon: "mdi-application-brackets",
                link: "/licitaciones/offerer",
              },
              {
                name: "Documentos",
                icon: "mdi-file",
                link: "/licitaciones/documents",
              },
            ],
          },
        ];
      }

      if (this.role == "viewerBidding") {
        return [
          {
            name: "Licitaciones",
            icon: "mdi-application-settings",
            links: [
              {
                name: "Páginas Licitaciones",
                icon: "mdi-application-brackets",
                link: "/licitaciones/config",
              },

              {
                name: "Documentos",
                icon: "mdi-file",
                link: "/licitaciones/documents",
              },
            ],
          },
        ];
      }
    },
    search: {
      get: function () {
        return this.$store.state.search;
      },
      set: function (newValue) {
        this.$store.commit("setSearch", newValue);
      },
    },
  },
  watch: {
    $route(e) {
      this.appFrame = e.meta.appFrame;
      this.navBar = e.meta.navBar;
      // this.titlepage(e.meta.titlepage);
      if (e.meta.allowedRoles && !e.meta.allowedRoles.includes(this.role)) {
        if (this.$router.history.current.fullPath != "/")
          this.$router.push({ path: "/" });
      }
    },
  },
  methods: {
    ...mapActions([
      "titlepage",
      "setUser",
      "Alert_",
      "setUserValidity_",
      "addBusiness",
      "_access_token",
      "_refresh_token",
    ]),
    logOut() {
      if (this.user) {
        fb.auth()
          .signOut()
          .then(() => {
            localStorage.removeItem("admin-enee-hn");
            this._access_token(null);
            this._refresh_token(null);
            if (this.$router.history.current.fullPath != "/") {
              this.$router.push({ path: "/" });
            }
            this.user = {};
            this.setUser({});
          });
      }
    },
    refreshPage() {
      // window.location = window.location.href + "?eraseCache=true";
      window.location.reload();
    },
  },
  mounted() {
    this.appFrame = this.$route.meta.appFrame;
    this.navBar = this.$route.meta.navBar;
    console.log("Environment", process.env.NODE_ENV);
    fb.auth().onAuthStateChanged((authUser) => {
      if (this.$route.meta.basePath) {
        if (authUser) {
          this.$binding(
            "user",
            db.collection(`appUsers`).doc(authUser.uid)
          ).then((user) => {
            this.user = user;
            if (!user.type || this.user.active == false) {
              console.debug("no entra");
              this.setUserValidity_(false);
              this.Alert_({
                text: "Este perfíl de usuario no tiene permitido ingresar a la plataforma",
                timeout: 2000,
                btn_closed: true,
                icon: false,
                iconType: "mdi-check",
                type: "error",
              });
              this.logOut();
            } else {
              this.setUser(user);

              if (this.$router.currentRoute.name === "login") {
                this.$router.push({
                  path: this.baseRoute[this.user.role],
                });
              }
              if (
                this.$router.currentRoute.meta.allowedRoles &&
                !this.$router.currentRoute.meta.allowedRoles.includes(user.role)
              ) {
                console.debug(this.$router.currentRoute.meta.allowedRoles);
                //validating user permissions for this route
                this.$router.push({ path: this.baseRoute[this.user.role] });
              }
            }
          });
        } else {
          this.loading = false;
          // this.$router.push({ path: "/" });
          if (
            this.$router.history.current.fullPath != "/" &&
            !this.user?.active
          )
            this.$router.push({ path: "/" });
        }
      }
    });
  },
};
</script>
<style lang="scss">
@import "@/main.scss";

$enee-50: #effafc;
$enee-100: #d7f1f6;
$enee-200: #b3e3ee;
$enee-300: #62c3db;
$enee-400: #43b0cd;
$enee-500: #2794b3;
$enee-600: #237697;
$enee-700: #23617b;
$enee-800: #245166;
$enee-900: #224457;
$enee-950: #122c3a;

.v-list-item__title {
  font-size: 13px !important;
}

.theme--dark.v-list {
  background: $enee-950 !important;
}

.v-list-item--active {
  color: #ffffff !important;
}

.v-list-item__title {
  font-size: 13px !important;
}

.v-list-group--active .v-list-group__items .v-list-item:hover {
  color: #ffffff !important;
  /* background: rgba(255, 255, 255, 0.504) !important; */
  border-radius: 10px 0px 0px 15px;
}

/* @import "./assets/scss/brands.scss"; */
/* @import "./assets/scss/fontawesome.scss"; */
/* @import "./assets/scss/light.scss"; */
/* @import "./assets/scss/regular.scss"; */
/* @import "./assets/scss/solid.scss"; */
/* @import "./_responsive.scss";  */
/* /* @import "./main.scss"; */

@font-face {
  font-family: "nexa";
  src: url("@/assets/fonts/nexa_regular-webfont.woff2") format("woff2");
  font-weight: 200;
  font-style: normal;
}

@font-face {
  font-family: "nexa";
  src: url("~@/assets/fonts/nexa_light-webfont.woff2") format("woff2");
  font-weight: 100;
  font-style: normal;
}

@font-face {
  font-family: "nexa";
  src: url("~@/assets/fonts/nexa_bold-webfont.woff2") format("woff2");
  font-weight: bold;
  font-style: normal;
}

html {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  user-select: none;
}

body .v-application {
  font-family: "nexa" !important;
  /* // position: fixed;
  // top:0; */
}

.nav-drawer-mobile {
  height: calc(100vh - 56px) !important;
  bottom: 0;
  margin-top: 56px !important;
  position: fixed !important;
}

body .v-application {
  .display-1,
  .display-2,
  .display-3,
  .display-4,
  .headline,
  .title,
  .subtitle-1,
  .subtitle-2,
  .body-1,
  .body-2,
  .caption,
  .overline {
    font-family: "nexa" !important;
  }
}

.v-card__title {
  word-break: normal !important;
}

.cardBox {
  background-color: white;
  border-radius: 10px;
  padding: 8px 4px !important;
  flex-grow: 0 !important;
  width: 100%;
  /* // min-width: calc(100vw - 24px); */
}

.menu-nav-mobile {
  position: fixed;
  bottom: 0;
  left: 0;
  z-index: 100;
}

.v-dialog {
  border-radius: 10px !important;
}

.v-card {
  border-radius: 10px !important;
  white-space: pre-wrap;
}

.v-snack__wrapper {
  border-radius: 10px !important;
  background-color: rgba(0, 0, 0, 0.9) !important;
}

.v-btn--rounded {
  /* // border-radius: 10px !important; */
  font-weight: bold !important;
  text-transform: unset !important;
}

.disclaimer-text {
  font-size: 14px;
  font-weight: 500;

  span {
    display: inline-block;
    line-height: 13px;
    font-weight: normal;
    font-size: 12px;
  }
}

body
  .v-application
  .v-text-field--rounded
  > .v-input__control
  > .v-input__slot {
  border-radius: 10px !important;
  padding: 0 14px !important;
}

input::-webkit-inner-spin-button,
input::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
  border: none;
  -webkit-text-fill-color: #000;
  box-shadow: none;
  -webkit-box-shadow: none;
  transition: background-color 5000s ease-in-out 0s;
}

.v-data-table tbody tr.v-data-table__expanded__content {
  -webkit-box-shadow: none !important;
  box-shadow: none !important;
}

.v-input__append-inner {
  margin-right: 10px;
}

.v-navigation-drawer__border {
  display: none;
}

.v-application--is-ltr .v-list-item__action:first-child,
.v-application--is-ltr .v-list-item__icon:first-child {
  margin-right: 6px !important;
}

.v-icon.v-icon {
  font-size: 16px !important;
}

.titleBasa svg {
  position: relative;
  height: 32px;
  top: 2px;
  /* // margin-right: 5px; */
  display: inline-block;
}

.v-list-item__avatar {
  /* // -ms-flex-item-align: center;
  // align-self: center;
  // -webkit-box-pack: start;
  // -ms-flex-pack: start;
  // justify-content: flex-start; */
  margin-bottom: 5px !important;
  margin-top: 5px !important;
}

.v-date-picker-table .v-btn {
  border-radius: 28px !important;
  /* // margin: 10px; */
}

/* // .v-picker--date {
//   padding: 10px 0;
// } */

.v-picker__actions.v-card__actions .row.no-gutters {
  padding: 0 10px;
}

/* 
// .v-picker__body{
//   // padding: 0 20px;
// } */

.v-tooltip__content {
  background-color: rgba(0, 0, 0, 0.9) !important;
  border-radius: 25px !important;
}

.v-data-table-header__icon {
  margin: 0 4px !important;
  color: #000 !important;
}

body
  .v-application
  .v-text-field--rounded
  > .v-input__control
  > .v-input__slot {
  border-radius: 10px !important;
}

.v-autocomplete__content.v-menu__content {
  border-radius: 10px !important;
}

.v-list-item--disabled {
  opacity: 0.3 !important;
}

.v-list-item--disabled .v-avatar.v-list-item__avatar img {
  opacity: 0.8 !important;
  filter: grayscale(100%);
}

.theme--light.v-list {
  background: #e6e6e6 !important;
}

.theme--dark.v-list {
  background: #122c3a !important;
}

.v-snack__content {
  justify-content: center !important;
  display: flex;
}

.submenu-items {
  width: 100%;
  /* // margin-top: 20%; */
}

.logo-menu {
  border-radius: 30px;
  width: 30px;
  height: 30px;
  margin-left: -10px;
  margin-right: 10px;
}

::-webkit-scrollbar {
  width: 12px;
  height: 12px;
}

::-webkit-scrollbar-track {
  background-color: #f2f2f2;
  border-radius: 2px;
}

::-webkit-scrollbar-thumb {
  background: #3a3a3a79 !important;
  border-radius: 2px;
}

.dev-tag {
  background-color: #101010;
  color: #fff;
  padding: 2px 10px;
  margin-left: 20px;
  border-radius: 10px;
}
</style>
