import Vue from "vue";
import VueRouter from "vue-router";
import login from "../views/login.vue";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "login",
    component: login,
    meta: {
      appFrame: false,
      navBar: false,
      basePath: true,
    },
  },
  {
    path: "/about",
    name: "about",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/AboutView.vue"),
  },
  {
    path: "/settings/navbar",
    name: "navbar",
    meta: {
      appFrame: true,
      navBar: true,
      basePath: true,
      allowedRoles: ["sudo", "admin"],
    },
    component: () =>
      import(
        /* webpackChunkName: "about" */ "../views/settings/settingsNavBar.vue"
      ),
  },
  {
    path: "/settings/logs",
    name: "logs",
    meta: {
      appFrame: true,
      navBar: true,
      basePath: true,
      allowedRoles: ["sudo"],
    },
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/accessLogs/logs.vue"),
  },
  {
    path: "/inicio/banners",
    name: "banners",
    meta: {
      appFrame: true,
      navBar: true,
      basePath: true,
      allowedRoles: ["sudo", "admin"],
    },
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/home/banners.vue"),
  },
  {
    path: "/autoproductores/history",
    name: "autoproductores",
    meta: {
      appFrame: true,
      navBar: true,
      basePath: true,
      allowedRoles: ["sudo", "support", "admin"],
    },
    component: () =>
      import(
        /* webpackChunkName: "lista-autoproductores" */ "../views/autoproductores/index.vue"
      ),
  },
  {
    path: "/autoproductores/actualizacion-mensual",
    name: "actualizacion-mensual",
    meta: {
      appFrame: true,
      navBar: true,
      basePath: true,
      allowedRoles: ["sudo", "admin"],
    },
    component: () =>
      import(
        /* webpackChunkName: "actualizacion-mensual" */ "../views/autoproductores/create-client.vue"
      ),
  },
  {
    path: "/inicio/galery",
    name: "galery",
    meta: {
      appFrame: true,
      navBar: true,
      basePath: true,
      allowedRoles: ["sudo", "admin"],
    },
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/home/galeryHome.vue"),
  },
  {
    path: "/news/categories",
    name: "categories",
    meta: {
      appFrame: true,
      navBar: true,
      basePath: true,
      allowedRoles: ["sudo", "admin"],
    },
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/news/categories.vue"),
  },
  {
    path: "*",
    name: "not-found",
    meta: {
      appFrame: true,
      navBar: true,
    },
    component: () => import("../views/notFound.vue"),
  },
  {
    path: "/news/news",
    name: "Noticias",
    meta: {
      appFrame: true,
      navBar: true,
      basePath: true,
      allowedRoles: ["sudo", "admin"],
    },
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/news/news.vue"),
  },
  {
    path: "/news/pages",
    name: "Pages",
    meta: {
      appFrame: true,
      navBar: true,
      basePath: true,
      allowedRoles: ["sudo", "admin"],
    },
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/news/pages.vue"),
  },
  {
    path: "/licitaciones/documents",
    name: "licitaciones",
    meta: {
      appFrame: true,
      navBar: true,
      basePath: true,
      allowedRoles: ["sudo", "biddingAdmin", "viewerBidding"],
    },
    component: () =>
      import(
        /* webpackChunkName: "about" */ "../views/licitaciones/documents/licitaciones.vue"
      ),
  },
  {
    path: "/documents/files",
    name: "files",
    meta: {
      appFrame: true,
      navBar: true,
      basePath: true,
      allowedRoles: ["sudo", "admin", "support"],
    },
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/documents/files.vue"),
  },
  {
    path: "/users/Admin",
    name: "userAdmin",
    meta: {
      appFrame: true,
      navBar: true,
      basePath: true,
      allowedRoles: ["sudo"],
    },
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/usersAdmin.vue"),
  },
  {
    path: "/demo",
    name: "demo",
    meta: {
      appFrame: true,
      navBar: true,
      basePath: true,
      allowedRoles: ["sudo"],
    },
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/Schedules.vue"),
  },
  {
    path: "/pages/pages",
    name: "Pages-Pages",
    meta: {
      appFrame: true,
      navBar: true,
      basePath: true,
      allowedRoles: ["sudo", "support"],
    },
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/pages/pages.vue"),
  },
  {
    path: "/pages/config",
    name: "Pages-Config",
    meta: {
      appFrame: true,
      navBar: true,
      basePath: true,
      allowedRoles: ["sudo", "support"],
    },
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/pages/config.vue"),
  },
  {
    path: "/licitaciones/config",
    name: "Licitaciones-Config",
    meta: {
      appFrame: true,
      navBar: true,
      basePath: true,
      allowedRoles: ["sudo", "biddingAdmin", "viewerBidding"],
    },
    component: () =>
      import(
        /* webpackChunkName: "about" */ "../views/licitaciones/config.vue"
      ),
  },
  {
    path: "/licitaciones/offererPending",
    name: "offererPending",
    meta: {
      appFrame: true,
      navBar: true,
      basePath: true,
      allowedRoles: ["sudo", "biddingAdmin", "viewerBidding"],
    },
    component: () =>
      import(
        /* webpackChunkName: "about" */ "../views/licitaciones/user-lists/usersPending.vue"
      ),
  },
  {
    path: "/licitaciones/offerer",
    name: "offerer",
    meta: {
      appFrame: true,
      navBar: true,
      basePath: true,
      allowedRoles: ["sudo", "biddingAdmin", "viewerBidding"],
    },
    component: () =>
      import(
        /* webpackChunkName: "about" */ "../views/licitaciones/user-lists/users.vue"
      ),
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

export default router;
